<template>
  <Page full>
    <super-users-body />
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page'
import SuperUsersBody from '../bodies/SuperUsers.vue'

export default {
  mixins: [PageMixin],
  name: 'SuperUsers',
  components: {
    SuperUsersBody
  }
}
</script>
