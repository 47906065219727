<template>
  <grid
    type="user"
    title="Users"
    :multiple="true"
    :isMain="isMain"
    :showDefaultFilterPresets="false"
    :order="[['user_time_created', 'desc']]"
    :filters="{
      user_is_company_user: '>0'
    }"
    :filterPresets="[
      {
        title: 'Company users',
        description: 'Users that are part of a company',
        filters: {
          user_is_company_user: '>0'
        },
        filterText: {
          user_is_company_user: 'true'
        }
      },
      {
        title: 'Client users',
        description: 'Users that are clients of companies',
        filters: {
          user_is_client: '>0'
        },
        filterText: {
          user_is_client: 'true'
        }
      },
      {
        title: 'Inactive users',
        description: 'Users set to inactive',
        filters: {
          user_status: '!a'
        },
        filterText: {
          user_status: 'Not active'
        }
      }
    ]"
    :visible="['user_preview', 'user_status', 'user_time_created', 'user_phone', 'user_email']"
  />
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import BodyMixin from '../mixins/Body'

export default {
  mixins: [BodyMixin],
  components: {
    Grid
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
